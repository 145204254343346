<template>
  <div>
    <div id="landing-header">
      <b-container class="pt-md-2">
        <b-row>
          <b-col md="6" class="p-5 text-container">
            <h1 class="text-white font-large-1 mb-2">{{ $t('landing.head_title') }}</h1>
            <h2 class="text-white mb-2">{{ $t('landing.subtitle') }}</h2>
            <b-button variant="success" size="lg" class="px-5" :to="{name: 'auth.register'}" v-if="!loggedIn">{{$t('buttons.register_now')}}</b-button>
          </b-col>
          <b-col md="6" class="p-5">
            <b-img src="/img/landing/index_header.svg" fluid></b-img>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="px-lg-5 mt-3">
      <b-row>
        <b-col md="4" class="d-flex flex-column text-center">
          <h3 class="text-primary">{{ $t('landing.step_1') }}</h3>
          <div class="step-circle bg-primary align-self-center mt-1 mb-1">
            <feather-icon icon="UserIcon" size="52"></feather-icon>
          </div>
          <h3 class="text-primary">{{ $t('landing.register') }}</h3>
        </b-col>
        <b-col md="4" class="d-flex flex-column text-center">
          <h3 class="text-primary">{{ $t('landing.step_2') }}</h3>
          <div class="step-circle bg-primary align-self-center mt-1 mb-1">
            <feather-icon icon="HeartIcon" size="52"></feather-icon>
          </div>
          <h3 class="w-50 align-self-center text-primary">{{ $t('landing.find_your_favorite_products_add_them_to_a_list') }}</h3>
        </b-col>
        <b-col md="4" class="d-flex flex-column text-center">
          <h3 class="text-primary">{{ $t('landing.step_3') }}</h3>
          <div class="step-circle bg-primary align-self-center mt-1 mb-1">
            <feather-icon icon="BellIcon" size="52"></feather-icon>
          </div>
          <h3 class="w-50 align-self-center text-primary">{{ $t('landing.we_will_send_you_notification_when_the_price_goes') }}</h3>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row justify-content-center mt-5 mb-5">
        <b-col md="6" lg="4" class="align-self-center">
          <h4 class="text-primary">{{ $t('landing.favorite_products') }}</h4>
          <h2>{{ $t('landing.your_favorite_products_in_pne_place') }}</h2>
          <p>{{ $t('landing.choose_compare_and_track_the_price_of_your_favorit') }}</p>
          <p>{{ $t('landing.every_few_hours_we_check_the_price_of_the_products') }}</p>
        </b-col>
        <b-col md="6" lg="4">
          <b-img src="/img/landing/favorites.svg" fluid></b-img>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row-reverse justify-content-center mt-5 mb-5">
        <b-col md="6" lg="4" class="align-self-center">
          <h4 class="text-primary">{{ $t('landing.searching') }}</h4>
          <h2>{{ $t('landing.save_time_with_our_search_engine') }}</h2>
          <p>{{ $t('landing.with_one_click_you_search_hundreds_of_sites_and_ov') }}</p>
          <p>{{ $t('landing.we_fill_our_catalog_with_tens_of_thousands_of_prod') }}</p>
        </b-col>
        <b-col md="6" lg="4">
          <b-img src="/img/landing/searching.svg" fluid></b-img>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row justify-content-center mt-5 mb-5">
        <b-col md="6" lg="4" class="align-self-center">
          <h4 class="text-primary">{{ $t('titles.price_history') }}</h4>
          <h2>{{ $t('landing.save_money_with_our_price_history') }}</h2>
          <p>
            {{ $t('landing.by_being_able_to_see_the_price_history_you_can_sav') }}<br />
            {{ $t('landing.it_is_enough_to_see_if_the_current_price_is_the_lo') }}
          </p>
          <p>{{ $t('landing.you_even_have_the_opportunity_to_write_the_desired') }}</p>
        </b-col>
        <b-col md="6" lg="4">
          <b-img src="/img/landing/save_money.svg" fluid></b-img>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row-reverse justify-content-center mt-5 mb-5">
        <b-col md="6" lg="4" class="align-self-center">
          <h4 class="text-primary">{{ $t('landing.product_reviews') }}</h4>
          <h2>{{ $t('landing.see_independent_reviews_from_other_users') }}</h2>
          <p>{{ $t('landing.you_can_write_product_reviews_as_well_as_read_thos') }}</p>
        </b-col>
        <b-col md="6" lg="4">
          <b-img src="/img/landing/reviews.svg" fluid></b-img>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row justify-content-center mt-5 mb-5">
        <b-col md="6" lg="4" class="align-self-center">
          <h4 class="text-primary">{{ $t('titles.notifications') }}</h4>
          <h2>{{ $t('landing.be_the_first_to_know_about_promotions') }}</h2>
          <p>{{ $t('landing.even_before_merchants_announce_the_promotions_on_t') }}</p>
        </b-col>
        <b-col md="6" lg="4">
          <b-img src="/img/landing/notifications.svg" fluid></b-img>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row-reverse justify-content-center mt-5 mb-5">
        <b-col md="6" lg="4" class="align-self-center">
          <h4 class="text-primary">{{ $t('landing.and_more') }}</h4>
          <h2>{{ $t('landing.we_do_not_stop_adding_new_opportunities') }}</h2>
          <p>{{ $t('landing.we_work_to_improve_the_online_shopping_of_our_user') }}</p>
        </b-col>
        <b-col md="6" lg="4">
          <b-img src="/img/landing/rocket.svg" fluid></b-img>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row justify-content-center mt-5 mb-5" v-if="!loggedIn">
        <b-col md="6" lg="4" class="align-self-center">
          <h4 class="text-primary">{{ $t('landing.free_registration') }}</h4>
          <h2>{{ $t('landing.get_started_for_free') }}</h2>
          <p>{{ $t('landing.change_the_way_you_shop') }}<br /> {{ $t('landing.quick_easy_convenient_and_everything_you_need_in_o') }}</p>
          <b-button variant="success" class="px-5 mb-3" :to="{name: 'auth.register'}">{{ $t('landing.register_now') }}</b-button>
        </b-col>
        <b-col md="6" lg="4">
          <b-img src="/img/landing/login.svg" fluid></b-img>
        </b-col>
      </b-row>
    </b-container>
    <prices embedded></prices>
    <b-container class="col-xl-6 col-lg-9 pb-2 d-flex flex-column flex-lg-row justify-content-around align-items-center">
      <a href='https://play.google.com/store/apps/details?id=com.pricex.pricex&utm_source=pricex.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img :alt="$t('messages.google_play')" :src="$t('images.google_play')" width="200"/></a>
      <a href="https://apps.apple.com/bg/app/pricex-app/id1606937299" target="_blank">
        <img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png" width="160" height="54" alt="PriceX for iOS" />
      </a>
      <a href="https://chrome.google.com/webstore/detail/pricexapp/ipdmjnngpjobbpagffadaogggkikpghl" title="PriceX Chrome extension" target="_blank">
        <img src="/img/pricex-chrome-extension.png" alt="PriceX Chrome extension" style="border: 1px solid #ccc;border-radius: 10px;background-color: #fff;" />
      </a>
    </b-container>
    <b-container class="col-xl-6 col-lg-9 pb-2">
      <b-button variant="none" :to="{name: 'prices'}">{{ $t('buttons.prices') }}</b-button>
      <b-button variant="none" :to="{name: 'terms', params: {page: 'terms-and-conditions'}}">{{ $t('buttons.terms_and_conditions') }}</b-button>
      <b-button variant="none" :to="{name: 'terms', params: {page: 'privacy-policy'}}">{{ $t('terms.privacy_policy') }}</b-button>
      <b-button variant="none" :to="{name: 'contacts'}">{{ $t('page_titles.contacts') }}</b-button>
    </b-container>
  </div>
</template>

<script>
import {BContainer, BRow, BCol, BImg, BButton} from "bootstrap-vue";
import Prices from "@/views/pricex/Prices";

export default {
  components: {
    Prices,
    BContainer, BRow, BCol, BImg, BButton
  },
  name: "Landing",
  metaInfo(){
    return {
      title: this.$t('page_titles.pricex_shop_wisely')
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.data.id
    }
  }
}
</script>
